import { configureScope } from '@sentry/browser'
import fetchPonyfill from 'fetch-ponyfill'
import Link from 'next/link'
import Router from 'next/router'
import Head from 'next/head'
import React from 'react'
import { connect } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import styled from 'styled-components'
import * as urlParser from 'url-parse'
import { loadCurrentUser } from '../components/currentUser/actions'
import { getNotifications } from '../lib/notification'
import { loadNotifications } from '../components/tgNotifications/actions'
import TgFooter from '../components/tgFooter/tgFooter'
import TgHeader from '../components/tgHeader/tgHeader'
import TgLogin from '../components/tgLogin/tgLogin'
import TgNewPassword from '../components/tgNewPassword/tgNewPassword'
import TgPrefetcher from '../components/tgPrefetcher/tgPrefetcher'
import TgRegister from '../components/tgRegister/tgRegister'
import {
  fetchCurrentUser,
  generateRandomId,
  getDisplayName,
  removeUrlQueryParameter,
  reportComponentDidCatch,
  reportError,
  reportMessage,
  trackCurrentPage
} from '../lib/utils'
import Image from 'next/image'
import { NextSeo } from 'next-seo'

const { fetch } = fetchPonyfill()

const notifyErrorOccured = (errorName) => {
  let message
  toast.dismiss()
  switch (errorName) {
    case 'MissingRequiredArguments':
      message = 'Tous les champs sont obligatoires.'
      break
    case 'PspAccountException':
      message = "Erreur d'accès à votre compte."
      break
    case 'PspAccountVerifiedException':
      message = 'Votre profil doit être complet afin de créer une cagnotte.'
      break
    case 'PspAccountCreationException':
      message = 'Erreur de création de cagnotte, veuillez réessayer plus tard!'
      break
    default:
      message =
        'Une erreur inconnue est survenue, veuillez réessayer plus tard!'
      break
  }

  return toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: false,
    draggable: false,
    bodyClassName: 'notifyError',
    pauseOnFocusLoss: false
  })
}

const StyledHero = styled.section`
  .heroWrapper {
    min-height: 640px;
    background-image: linear-gradient(152deg, #0facf3, #9adaf5);
    padding: 160px 80px 100px 80px;
  }

  h1 {
    font-family: gotham-rounded-book, sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 0.88;
    letter-spacing: 0.1px;
    color: #fff;
    padding-bottom: 25px;
  }

  .tagLine {
    font-family: gotham-rounded-book, sans-serif;
    font-size: 24px;
    line-height: 1.46;
    letter-spacing: 0.1px;
    color: #fff;
    max-width: 620px;
    padding-bottom: 30px;
  }

  a {
    width: 240px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 35px;
    background-image: linear-gradient(to bottom, #fad961, #f76b1c);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.3px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    box-shadow: 6px 6px 13px 0 rgba(15, 172, 243, 0.5);
  }

  @media (max-width: 650px) {
    .heroWrapper {
      text-align: center;
      padding: 160px 30px 80px 30px;
    }

    h1 {
      font-size: 26px;
      text-align: center;
      line-height: 1.35;
    }

    .tagLine {
      font-size: 18px;
      text-align: center;
      line-height: 1.11;
    }

    a {
      font-size: 16px;
      margin: 0 auto;
    }
  }

  @media (max-width: 425px) {
  }
`

class Hero extends React.PureComponent {
  render() {
    return (
      <StyledHero>
        <div className="heroWrapper">
          <h1>La première plateforme de crowdsaving.</h1>
          <div className="tagLine">
            Financez vos proches en leur prêtant vos économies en toute
            confiance.
          </div>
          <Link href="/create-piggy">
            <a className="hvr-forward">CRÉER UNE TIRELIRE</a>
          </Link>
        </div>
      </StyledHero>
    )
  }
}

const StyledHowItWorks = styled.section`
  .howItWorksWrapper {
    background: #fafafa;
    padding: 90px 20px 20px 20px;
  }

  h1,
  h3 {
    font-family: gotham-rounded-book, sans-serif;
    color: #151515;
    font-style: normal;
    text-rendering: optimizeLegibility;
    margin: 0;
    padding: 0 0 3rem 0;
    text-align: center;
    letter-spacing: -0.1rem;
    line-height: 1.35;
    font-size: 3.1rem;
    font-weight: bold;
  }

  .howItWorks {
    display: flex;
    flex-wrap: wrap;
  }

  .aboutRow {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .aboutCell {
    width: 250px;
    text-align: center;
    padding-bottom: 40px;
    font-family: 'muli-regular', sans-serif;
    font-size: 1.6rem;
    line-height: 1.875;
    color: #767676;
  }

  h3 {
    font-size: 1.9rem;
    padding-bottom: 25px;
    text-transform: uppercase;
  }

  img {
    width: 80px;
    height: 80px;
  }

  details {
    font-family: 'muli-regular', sans-serif;
    font-size: 1.6rem;
    line-height: 1.875;
    color: #767676;
  }
`

class HowItWorks extends React.PureComponent {
  render() {
    return (
      <StyledHowItWorks>
        <div className="howItWorksWrapper">
          <div className="howItWorks">
            <div className="aboutRow">
              <div className="aboutCell">
                <Image
                  alt="togethrust"
                  src="/static/img/create.svg"
                  width="80"
                  height="80"
                  layout="fixed"
                  placeholder="blur"
                  blurDataURL="/static/img/create.svg"
                />
                <h3>JE CRÉE</h3>
                <div className="details">
                  Je crée et personnalise
                  <br />
                  ma tirelire selon mes besoins.
                </div>
              </div>
              <div className="aboutCell">
                <Image
                  alt="togethrust"
                  src="/static/img/invite.svg"
                  layout="fixed"
                  width="80"
                  height="80"
                  placeholder="blur"
                  blurDataURL="/static/img/invite.svg"
                />
                <h3>J'INVITE</h3>
                <div className="details">
                  J'invite des amis
                  <br />
                  qui approuvent la configuration.
                </div>
              </div>
            </div>
            <div className="aboutRow">
              <div className="aboutCell">
                <Image
                  alt="togethrust"
                  src="/static/img/contribute.svg"
                  layout="fixed"
                  width="80"
                  height="80"
                  placeholder="blur"
                  blurDataURL="/static/img/contribute.svg"
                />
                <h3>ON COTISE</h3>
                <div className="details">
                  Chacun contribue
                  <br />
                  le montant convenu.
                </div>
              </div>
              <div className="aboutCell">
                <Image
                  alt="togethrust"
                  src="/static/img/distribute.svg"
                  layout="fixed"
                  width="80"
                  height="80"
                  placeholder="blur"
                  blurDataURL="/static/img/distribute.svg"
                />
                <h3>ON DISTRIBUE</h3>
                <div className="details">
                  L'argent est redistribué
                  <br />à tour de rôle.
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledHowItWorks>
    )
  }
}

const StyledServices = styled.section`
  .servicesWrapper {
    background-image: linear-gradient(146deg, #0facf3, #11adf3 29%, #9adaf5);
    padding: 80px 20px 80px 20px;
    font-family: gotham-rounded-book, sans-serif;
  }

  h2 {
    font-size: 35px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.1px;
    text-align: center;
    color: #fff;
    margin: 0 auto 21px auto;
  }

  .titleDivider {
    width: 200px;
    height: 2px;
    border-radius: 6px;
    background-color: #ffffff;
    margin: 0 auto;
  }

  .tagLine {
    text-align: center;
    font-size: 25px;
    line-height: 1.4;
    letter-spacing: 0.1px;
    color: #fff;
    padding: 20px 10px;
    max-width: 1150px;
    margin: 0 auto;
  }

  .emphasis {
    font-family: gotham-rounded-bold, sans-serif;
  }

  .servicesDetails {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 230px;
  }

  .serviceLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .serviceCell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    margin: 20px 0;
  }

  .serviceImageWrapper {
    text-align: center;
    width: 90px;
    min-width: 90px;
  }

  .serviceTitle {
    font-family: gotham-rounded-book, sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #fff;
  }

  .serviceInnerContent {
    height: 112px;
  }

  .serviceContent {
    font-size: 17px;
    line-height: 1.53;
    letter-spacing: 0.1px;
    color: #fff;
    padding-top: 10px;
  }

  .videoWrapper {
    width: 430px;
    height: 240px;
    margin: 0 40px 0 30px;
    justify-content: center;
    align-items: center;
  }

  video {
    width: 430px;
    height: 240px;
    margin: 0 auto;
    display: block;
  }

  @media screen and (max-width: 1100px) {
    .servicesDetails {
      flex-wrap: wrap;
    }

    .videoWrapper {
      min-width: 100%;
      width: 100%;
      margin: 0 0 30px 0;
      order: 0;
    }

    .economyGuarantee {
      order: 1;
    }

    .unanimitySecurity {
      order: 2;
    }

    .servicesDetails {
      height: auto;
    }

    .serviceLine {
      flex-direction: row;
      justify-content: space-between;
      max-width: 800px;
      flex-wrap: wrap;
      align-items: center;
    }

    .serviceCell {
      margin: 40px 10px;
    }
  }

  @media screen and (max-width: 700px) {
    .serviceLine {
      flex-direction: column;
    }

    .serviceCell {
      margin: 20px 10px;
    }

    video {
      width: calc(100% - 20px);
      height: 240px;
      margin: 0 auto;
      display: block;
    }
  }

  @media screen and (max-width: 400px) {
    .serviceCell {
      margin: 20px 0px;
    }
  }
`

class Services extends React.PureComponent {
  render() {
    return (
      <StyledServices>
        <div className="servicesWrapper">
          <h2>Services</h2>
          <div className="titleDivider" />
          <div className="tagLine">
            Avant, il y’avait la banque et le crédit de consommation. Ensuite,
            il y’a eu le crowdfunding.
            <br />
            Désormais, il y’a <span className="emphasis">Togethrust</span>
          </div>
          <div className="servicesDetails">
            <div className="serviceLine economyGuarantee">
              <div className="serviceCell">
                <span className="serviceImageWrapper">
                  <Image
                    src="/static/img/economic.png"
                    width="65"
                    height="41"
                    alt="Économique"
                    layout="fixed"
                    placeholder="blur"
                    blurDataURL="/static/img/economic.png"
                  />
                </span>
                <div className="serviceInnerContent">
                  <div className="serviceTitle">Économique</div>
                  <div className="serviceContent">
                    Aucun frais de transaction
                  </div>
                </div>
              </div>
              <div className="serviceCell">
                <span className="serviceImageWrapper">
                  <Image
                    src="/static/img/guarantee.png"
                    width="41"
                    height="58"
                    alt="Garantie"
                    layout="fixed"
                    placeholder="blur"
                    blurDataURL="/static/img/guarantee.png"
                  />
                </span>
                <div className="serviceInnerContent">
                  <div className="serviceTitle">Garantie</div>
                  <div className="serviceContent">
                    Génération d'une reconnaissance de dette
                  </div>
                </div>
              </div>
            </div>
            <div className="videoWrapper">
              <video controls>
                <source
                  src="/static/videos/presentation.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="serviceLine unanimitySecurity">
              <div className="serviceCell">
                <span className="serviceImageWrapper">
                  <Image
                    src="/static/img/unanimity.png"
                    width="58"
                    height="56"
                    alt="Unanimité"
                    layout="fixed"
                    placeholder="blur"
                    blurDataURL="/static/img/unanimity.png"
                  />
                </span>
                <div className="serviceInnerContent">
                  <div className="serviceTitle">Unanimité</div>
                  <div className="serviceContent">
                    Les paramètres de la tirelire sont validés par l’ensemble
                    des membres
                  </div>
                </div>
              </div>
              <div className="serviceCell">
                <span className="serviceImageWrapper">
                  <Image
                    src="/static/img/security.png"
                    width="35"
                    height="54"
                    alt="Sécurité"
                    layout="fixed"
                    placeholder="blur"
                    blurDataURL="/static/img/security.png"
                  />
                </span>
                <div className="serviceInnerContent">
                  <div className="serviceTitle">Sécurité</div>
                  <div className="serviceContent">
                    Sécurisé par notre prestataire de paiement.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledServices>
    )
  }
}

const StyledTheyTrustUs = styled.section`
  .trustWrapper {
    background: #fafafa;
    padding: 40px 20px 20px 20px;
  }

  h2 {
    font-family: gotham-rounded-book, sans-serif;
    color: #063f59;
    font-style: normal;
    text-rendering: optimizeLegibility;
    margin: 0;
    padding: 0 0 4rem 0;
    text-align: center;
    letter-spacing: -0.1rem;
    line-height: 1.35;
    font-size: 3.1rem;
    font-weight: bold;
  }

  .trustLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  img {
    width: 260px;
    height: 120px;
  }

  @media (max-width: 820px) {
    img {
      width: 220px;
    }
  }

  @media (max-width: 700px) {
    .trustLinks {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
    }
  }
`

class TheyTrustUs extends React.PureComponent {
  render() {
    return (
      <StyledTheyTrustUs>
        <div className="trustWrapper">
          <h2>Ils nous font confiance&nbsp;:</h2>
          <div className="trustLinks">
            <a
              href="https://finance-innovation.org/"
              className="hvr-float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Finance Innovation"
                src="/static/img/finance-innovation.svg"
              />
            </a>
            <a
              href="http://www.escpeurope.eu/"
              className="hvr-float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="ESCP Europe" src="/static/img/escp-europe.png" />
            </a>
            <a
              href="https://www.blue-factory.eu/"
              className="hvr-float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="Blue Factory" src="/static/img/blue-factory.svg" />
            </a>
          </div>
        </div>
      </StyledTheyTrustUs>
    )
  }
}

const StyledCreatePiggy = styled.section`
  .createPiggyWrapper {
    background-image: linear-gradient(to bottom, #fad961, #f76b1c);
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h2 {
    font-family: gotham-rounded-book, sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 0.88;
    letter-spacing: 0.1px;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
  }

  a {
    text-align: center;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: #f77e28;
    width: 243px;
    height: 56px;
    line-height: 56px;
    border-radius: 35px;
    box-shadow: 6px 6px 13px 0 #f77e28;
    background-color: #fff;
    display: inline-block;
  }

  @media (max-width: 620px) {
    .createPiggyWrapper {
      height: 300px;
    }

    h2 {
      font-size: 22px;
      line-height: 1.59;
      padding: 30px 20px;
    }

    a {
      font-size: 16px;
    }
  }
`

class CreatePiggy extends React.PureComponent {
  render() {
    return (
      <StyledCreatePiggy>
        <div className="createPiggyWrapper">
          <h2>Je crée ma première tirelire</h2>
          <Link href="/create-piggy">
            <a className="hvr-float">C’EST PARTI !</a>
          </Link>
        </div>
      </StyledCreatePiggy>
    )
  }
}

const StyledContactUs = styled.section`
  .contactUsWrapper {
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: gotham-rounded-book, sans-serif;
    padding: 50px 20px;
  }

  h2 {
    font-size: 35px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.1px;
    text-align: center;
    color: #063f59;
    margin: 0 auto 21px auto;
  }

  .titleDivider {
    width: 200px;
    height: 2px;
    border-radius: 6px;
    background-color: #063f59;
    margin: 0 auto;
  }

  form {
    width: 600px;
    padding-top: 40px;
  }

  .identityWrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
  }

  .identityWrapper .inputWrapper {
    width: calc(50% - 10px);
  }

  label {
    display: block;
    font-size: 17px;
    letter-spacing: 0.1px;
    color: #063f59;
    padding-bottom: 5px;
  }

  input[type='text'],
  input[type='email'],
  textarea {
    display: block;
    padding: 10px 20px;
    background-color: #fff;
    width: 100%;
    border: solid 1px #d5d5d5;
    border-radius: 10px;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 15px;
    color: #063f59;

    &:focus {
      border: 1px solid #0facf3;
    }
  }

  .inputWrapper input {
    height: 52px;
  }

  textarea {
    height: 106px;
    resize: none;
  }

  .submitWrapper {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    padding-top: 20px;
  }

  button {
    width: 160px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 35px;
    background-image: linear-gradient(to bottom, #fad961, #f76b1c);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.3px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-family: gotham-rounded-book, sans-serif;
  }

  .buttonLoading {
    width: 30px;
    height: 30px;
  }

  @media screen and (max-width: 650px) {
    form {
      width: 100%;
    }

    .identityWrapper {
      flex-direction: column;
      padding-bottom: 0;
    }

    .identityWrapper .inputWrapper {
      width: 100%;
    }

    .inputWrapper {
      padding-bottom: 15px;
    }

    .submitWrapper {
      justify-content: center;
    }

    button {
      width: 300px;
    }
  }
`

class ContactUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: props.user?.email || '',
      name: props.user ? getDisplayName(props.user) : '',
      message: '',
      pending: false,
      success: false,
      failure: false
    }
  }

  handleChange = (event) => {
    const newState = Object.assign({}, this.state)
    newState[event.target.name] = event.target.value
    this.setState(newState)
  }

  resetState = () => {
    const defaultState = {
      email: this.props.user ? this.props.user.email : '',
      name: this.props.user ? getDisplayName(this.props.user) : '',
      message: '',
      pending: false,
      success: false,
      failure: false
    }

    this.setState(defaultState)
  }

  notifyEmailSent() {
    return toast.success('Message envoyé !', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      draggable: false,
      bodyClassName: 'notifySuccess',
      pauseOnFocusLoss: false
    })
  }

  handleSubmit = async (event) => {
    if (this.state.pending) {
      return
    }
    const { email, name, message } = this.state
    event.preventDefault()
    this.setState({ pending: true })
    setTimeout(async () => {
      try {
        const response = await fetch(
          `${window.location.origin}/api/landing/contact-us`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-tgth-trace-id': generateRandomId()
            },
            body: JSON.stringify({ email, name, message })
          }
        )

        const body = await response.json()

        if (response.ok) {
          console.log('Message submitted for email: ' + this.state.email)
          reportMessage(
            'Message submitted for email: ' + this.state.email,
            'info'
          )
          this.notifyEmailSent()
          this.resetState()
        } else {
          console.error('Problem while submitting the message')
          reportMessage(
            `Problem while submitting the message - ${JSON.stringify(
              body.name
            )}`
          )
          notifyErrorOccured(body?.name)
          this.setState({ pending: false })
        }
      } catch (e) {
        reportError(e)
        this.setState({ pending: false })
        notifyErrorOccured()
        console.error('Problem while submitting the message')
      }
    }, 500)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps &&
      (prevProps?.user?.email !== this.props?.user?.email ||
        prevProps?.user?.name !== this.props?.user?.name)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        email: this.props.user ? this.props.user.email : '',
        name: this.props.user ? getDisplayName(this.props.user) : ''
      })
    }
  }

  render() {
    return (
      <>
        <StyledContactUs>
          <div className="contactUsWrapper" id="contactUs">
            <h2>Nous Contacter</h2>
            <div className="titleDivider" />
            <form method="post" onSubmit={this.handleSubmit}>
              <div className="identityWrapper">
                <div className="inputWrapper">
                  <label htmlFor="contactName">Nom :</label>
                  <input
                    type="text"
                    name="name"
                    id="contactName"
                    required
                    value={this.state.name}
                    onChange={this.handleChange}
                    readOnly={this.props.user}
                  />
                </div>
                <div className="inputWrapper">
                  <label htmlFor="contactEmail">Adresse email :</label>
                  <input
                    type="text"
                    name="email"
                    id="contactEmail"
                    required
                    value={this.state.email}
                    onChange={this.handleChange}
                    readOnly={this.props.user}
                  />
                </div>
              </div>
              <div>
                <div className="inputWrapper">
                  <label htmlFor="contactMessage">Message :</label>
                  <textarea
                    name="message"
                    id="contactMessage"
                    required
                    value={this.state.message}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="submitWrapper">
                <button type="submit" name="submit" className="hvr-float">
                  {!this.state.pending && <span>ENVOYER</span>}
                  {this.state.pending && (
                    <img
                      className="buttonLoading"
                      src="/static/img/loading.svg"
                      alt="loading"
                    />
                  )}
                </button>
              </div>
            </form>
          </div>
        </StyledContactUs>
        <ToastContainer theme="colored" />
      </>
    )
  }
}

class Home extends React.Component {
  static async getInitialProps({ reduxStore, req, res, query, pathname }) {
    let currentUser
    try {
      const { isAuthenticated: isLogged, user } = await fetchCurrentUser(req)
      currentUser = user
      if (isLogged) {
        reduxStore.dispatch(loadCurrentUser(user))
        const { notifications } = reduxStore.getState()
        if (!notifications) {
          const { notifications, unreadCounter } = await getNotifications(req)
          reduxStore.dispatch(loadNotifications(notifications, unreadCounter))
        }
      }
    } catch (err) {
      reportError(err, { req, res, query, pathname })
    }
    return { user: currentUser }
  }

  constructor(props) {
    super(props)

    this.state = {
      openLoginModal: false,
      openRegisterModal: false,
      openNewPasswordModal: false,
      redirectPath: null
    }
  }

  displayInvalidVerificationCodeError = () => {
    const url = urlParser(window.location.href, true)
    if (!url.query.invalidCode) {
      return
    }

    toast.error('Ce lien est invalide, veuillez réessayer.', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      draggable: false,
      bodyClassName: 'notifyError',
      pauseOnFocusLoss: false
    })

    removeUrlQueryParameter('invalidCode', window.location.href)
  }

  displayAccountCreationError = () => {
    const url = urlParser(window.location.href, true)
    if (!url.query.accountCreationError) {
      return
    }

    toast.error(
      'Problème de création de compte. Veuillez contacter le support',
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        draggable: false,
        bodyClassName: 'notifyError',
        pauseOnFocusLoss: false
      }
    )

    removeUrlQueryParameter('accountCreationError', window.location.href)
  }

  displayLoginModal = () => {
    const url = urlParser(window.location.href, true)
    if (!url.query.showLogin) {
      return
    }

    if (url.query.sessionExpired) {
      toast.error('Votre session est expirée, veuiller vous reconnecter', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        draggable: false,
        bodyClassName: 'notifyError',
        pauseOnFocusLoss: false
      })
      removeUrlQueryParameter('sessionExpired', window.location.href)
    }

    if (url.query.redirectPath) {
      this.setState({ redirectPath: url.query.redirectPath })
      removeUrlQueryParameter('redirectPath', window.location.href)
    }

    removeUrlQueryParameter('showLogin', window.location.href)
    this.onLoginModalOpen()
  }

  displayRegisterModal = () => {
    const url = urlParser(window.location.href, true)
    if (!url.query.showRegister) {
      return
    }

    removeUrlQueryParameter('showRegister', window.location.href)
    this.onRegisterModalOpen()
  }

  notifyLogoutSuccess = () => {
    const url = urlParser(window.location.href, true)
    if (!url.query.logoutSuccess) {
      return
    }

    removeUrlQueryParameter('logoutSuccess', window.location.href)
    return toast.success('Vous êtes déconnecté!', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      draggable: false,
      bodyClassName: 'notifySuccess',
      pauseOnFocusLoss: false
    })
  }

  onLoginModalOpen = () => {
    this.setState({ openLoginModal: true })
  }

  onLoginModalClose = () => {
    this.setState({ openLoginModal: false })
  }

  onRegisterModalOpen = () => {
    this.setState({ openRegisterModal: true })
  }

  onRegisterModalClose = () => {
    this.setState({ openRegisterModal: false })
  }

  handleNewPasswordModalOpen = () => {
    this.setState({ openNewPasswordModal: true })
  }

  onNewPasswordModalClose = () => {
    this.setState({ openNewPasswordModal: false })
  }

  onSwitchToRegisterModal = () => {
    this.setState({
      openRegisterModal: true,
      openLoginModal: false,
      openNewPasswordModal: false
    })
  }

  onSwitchToLoginModal = () => {
    this.setState({
      openRegisterModal: false,
      openLoginModal: true,
      openNewPasswordModal: false
    })
  }

  onSwitchToNewPasswordModal = () => {
    this.setState({
      openRegisterModal: false,
      openLoginModal: false,
      openNewPasswordModal: true
    })
  }

  componentDidMount() {
    const props = this.props
    configureScope((scope) => {
      const context = {}
      if (props.user) {
        context.email = props.user.email
        context.username = props.user.name
        context.id = props.user.id
      }
      scope.setUser(context)
    })
    trackCurrentPage()

    this.displayInvalidVerificationCodeError()
    this.displayLoginModal()
    this.displayRegisterModal()
    this.notifyLogoutSuccess()
    this.displayAccountCreationError()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentWillUnmount() {}

  componentDidCatch(error, errorInfo) {
    reportComponentDidCatch(error, errorInfo)
  }

  render() {
    return (
      <>
        <NextSeo
          title="Bienvenue chez Togethrust"
          description="La première plateforme de crowdsaving."
          canonical="https://togethrust.com/home"
          openGraph={{
            url: 'https://togethrust.com/home',
            title: 'Bienvenue chez Togethrust',
            description: 'La première plateforme de crowdsaving.',
            images: [
              {
                url: 'https://togethrust.com/static/img/tgth.png',
                width: 1482,
                height: 302,
                alt: 'Logo Togethrust',
                type: 'image/png'
              }
            ],
            site_name: 'Togethrust'
          }}
          facebook={{
            appId: '197102964018102'
          }}
        />
        <Head>
          <title>Bienvenue Chez togethrust</title>
          {this.props.styles}
        </Head>
        <TgHeader
          handleOpenLoginModal={this.onLoginModalOpen}
          openRegisterModal={this.onRegisterModalOpen}
        />
        <Hero />
        <HowItWorks />
        <Services />
        <TheyTrustUs />
        <CreatePiggy />
        <ContactUs user={this.props.user} />
        <TgFooter />
        {this.state.openLoginModal && (
          <TgLogin
            openModal={this.state.openLoginModal}
            handleModalClose={this.onLoginModalClose}
            handleSwitchToRegisterModal={this.onSwitchToRegisterModal}
            handleSwitchToNewPasswordModal={this.onSwitchToNewPasswordModal}
            redirectPath={this.state.redirectPath}
          />
        )}
        {this.state.openRegisterModal && (
          <TgRegister
            openModal={this.state.openRegisterModal}
            handleModalClose={this.onRegisterModalClose}
            handleSwitchToLoginModal={this.onSwitchToLoginModal}
          />
        )}
        {this.state.openNewPasswordModal && (
          <TgNewPassword
            openModal={this.state.openNewPasswordModal}
            handleModalClose={this.onNewPasswordModalClose}
            handleSwitchToLoginModal={this.onSwitchToLoginModal}
            handleSwitchToRegisterModal={this.onSwitchToRegisterModal}
          />
        )}
        <TgPrefetcher routes={['/profile', '/dashboard']} />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state
  }
}

const mapDispatchToProps = (dispatch, getState) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
